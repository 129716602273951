.radio-selection-section {
  max-width: 500px;
  margin: auto;
}

.radio-form-section {
  /* max-width: fit-content; */
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.input-radio-component {
  text-align: left;
}