/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 

}

.homepage {
    background-color: #E2BD94;
    margin: 0;
    height: 100vh;
}

.homepage .homepage-navigation {
    padding: 35px;
}

.homepage .homepage-attention-grabber .break {
    height: 80px;
}

.homepage .homepage-attention-grabber {
    margin: 35px;
    min-height: 400px;
    text-align: center;
}

.homepage .homepage-attention-grabber h2 {
    max-width: 60%;
    margin: auto;
}

.homepage .homepage-attention-grabber p {
    margin-top: 30px;
}

.homepage .homepage-explaination {
    width: 60%;
    margin: auto;
}
