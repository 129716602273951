.form {
  align-items: center;
  margin: 60px 0 60px 0;
}

.form form {
  text-align: center;
}

.form form button {
  border: 1px solid black;
  height: 40px;
  width: 100px;
  border-radius: 8px;
  background-color: white;
}

.form form button:hover {
  color: #000000;
  background-color: #E2BD94;
  border: none;
}

.input-container {
  margin: 20px 30px;
}