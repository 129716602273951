/* 
Color Palette
Main         - #E2BD94
Light Accent - #A4BCC5
Dark Accent  - #C88685
Light Shade  - #F8F8F8
Dark Shade   - #867185

*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 18px;
  font-family: 'Arvo', serif;
}

p#bold {
  font-weight: 700;
}

#break-div{
  padding: 40px;
}

.page-title {
  padding: 80px;
  width: fit-content;
}

/* Images */
img#senior-texting-img {
  width: 750px;
}
